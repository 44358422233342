

















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Sorter } from '@/util/Sorter';

@Component({})
export default class TableSortArrowComponent extends Vue {
  @Prop() name!: string;
  @Prop() sorting!: Sorter;
}
