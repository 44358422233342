var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.sorting.currCol === _vm.name
    ? _c(
        "div",
        { staticClass: "inline-block" },
        [
          _vm.sorting.sorted === "dsc"
            ? _c("font-awesome-icon", {
                staticClass: "ml-2 align-top",
                attrs: { id: "dsc", icon: ["fas", "sort-down"] }
              })
            : _vm._e(),
          _vm.sorting.sorted === "asc"
            ? _c("font-awesome-icon", {
                staticClass: "ml-2 align-bottom",
                attrs: { id: "asc", icon: ["fas", "sort-up"] }
              })
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }