export type SortableValues = string | number | boolean | Date | undefined;

export class MultiSortProperty<T> {
  orderInSort: number;
  sortValue: (t: T) => SortableValues;

  constructor(orderInSort: number, sortValue: (t: T) => SortableValues) {
    this.orderInSort = orderInSort;
    this.sortValue = sortValue;
  }
}

function isNumber(value: string | boolean | number): boolean {
  return value != null && value !== '' && !isNaN(Number(value.toString()));
}

function compare<P>(varA: P, varB: P) {
  if (varA > varB) {
    return 1;
  } else if (varA < varB) {
    return -1;
  }

  return 0;
}

function numberComparator(varA: unknown, varB: unknown) {
  const aNum = Number(varA);
  const bNum = Number(varB);
  return compare(aNum, bNum);
}

function stringComparator(varA: unknown, varB: unknown) {
  const aString = String(varA).toLowerCase();
  const bString = String(varB).toLowerCase();
  // if string is a valid, parsable number
  if (isNumber(aString) && isNumber(bString)) {
    const aNum = Number(varA);
    const bNum = Number(varB);
    return compare(aNum, bNum);
  } else {
    return compare(aString, bString);
  }
}

function invalidTypesComparator(aType: string, bType: string) {
  // sort undefined below defined if possible
  if (aType === 'undefined') {
    return -1;
  }
  if (bType === 'undefined') {
    return 1;
  }
  throw new Error('Invalid args passed to compare');
}

function nullComparator(varA: unknown, varB: unknown) {
  if (varA === varB) {
    return 0;
  }

  if (varA !== null) {
    return 1;
  }

  return -1;
}

function genericComparator(varA: unknown, varB: unknown) {
  if (varA === null || varB === null) {
    return nullComparator(varA, varB);
  }

  const aType = typeof varA;
  const bType = typeof varB;
  if (
    (aType == 'number' && bType == 'number') ||
    (aType == 'boolean' && bType == 'boolean')
  ) {
    return numberComparator(varA, varB);
  } else if (aType === 'string' && bType === 'string') {
    return stringComparator(varA, varB);
  } else if (aType !== bType) {
    return invalidTypesComparator(aType, bType);
  } else {
    return compare(varA, varB);
  }
}

function versionComparator<P>(verA: P, verB: P) {
  if (typeof verA !== 'string' || typeof verB !== 'string') {
    return genericComparator(verA, verB);
  }
  const versionA = String(verA);
  const versionB = String(verB);
  const aArray = versionA.split('.');
  const bArray = versionB.split('.');
  let compareDirection = 0;
  for (let i = 0; i < aArray.length; i++) {
    if (bArray.length <= i) {
      compareDirection = 1;
      break;
    }
    const varA = aArray[i];
    const varB = bArray[i];

    if (varA === varB) {
      continue;
    }

    return genericComparator(varA, varB);
  }

  if (aArray.length < bArray.length) {
    compareDirection = -1;
  }

  return compareDirection;
}
export class Sorter {
  private currCol = '';
  private sorted = '';

  public sort<T>(
    sortValue: (t: T) => SortableValues,
    collection: T[],
    col?: string
  ) {
    if (col) {
      this.updateSortDirection(col);
    }
    return this.sortOnProperty(sortValue, collection, genericComparator);
  }

  public multiSort<T>(sortProperties: MultiSortProperty<T>[], collection: T[]) {
    sortProperties = this.sort(
      (sortProperty) => sortProperty.orderInSort,
      sortProperties
    );

    return collection.sort((a, b) => {
      let sortResult = 0;
      for (const sortProperty of sortProperties) {
        sortResult = genericComparator(
          sortProperty.sortValue(a),
          sortProperty.sortValue(b)
        );
        if (sortResult !== 0) {
          break;
        }
      }
      return sortResult;
    });
  }

  public sortVersions<T>(
    sortValue: (t: T) => SortableValues,
    collection: Array<T>,
    col?: string
  ) {
    if (col) {
      this.updateSortDirection(col);
    }
    return this.sortOnProperty(sortValue, collection, versionComparator);
  }

  private updateSortDirection(col: string) {
    //check if this column has been sorted, otherwise default to ascending
    if (col === this.currCol && this.sorted === 'asc') {
      this.sorted = 'dsc';
    } else {
      this.sorted = 'asc';
    }
    this.currCol = col;
  }

  private sortOnProperty<T>(
    property: (t: T) => SortableValues,
    collection: T[],
    comparator: (a: SortableValues, b: SortableValues) => number
  ) {
    return collection.sort((a, b) => {
      const propA = property(a);
      const propB = property(b);
      return comparator(propA, propB) * (this.sorted === 'dsc' ? -1 : 1);
    });
  }
}
